// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acara-js": () => import("./../src/pages/acara.js" /* webpackChunkName: "component---src-pages-acara-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hioi-js": () => import("./../src/pages/hioi.js" /* webpackChunkName: "component---src-pages-hioi-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-malva-js": () => import("./../src/pages/malva.js" /* webpackChunkName: "component---src-pages-malva-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-stilllifes-js": () => import("./../src/pages/stilllifes.js" /* webpackChunkName: "component---src-pages-stilllifes-js" */),
  "component---src-pages-styles-index-example-css-js": () => import("./../src/pages/styles/IndexExampleCss.js" /* webpackChunkName: "component---src-pages-styles-index-example-css-js" */),
  "component---src-pages-teamsequel-js": () => import("./../src/pages/teamsequel.js" /* webpackChunkName: "component---src-pages-teamsequel-js" */),
  "component---src-pages-wog-js": () => import("./../src/pages/wog.js" /* webpackChunkName: "component---src-pages-wog-js" */),
  "component---src-pages-works-js": () => import("./../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

